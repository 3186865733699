import LaravelError from '@/api/model/LaravelError';
import { useToast } from 'bootstrap-vue-next';
import { ref } from 'vue';

export function useErrors() {
    const { show } = useToast();

    const validationErrors = ref<Record<string, string | string[]>>({});

    function getTitle(error: unknown): string {
        if (error instanceof LaravelError) {
            switch (error.localCause?.response?.status) {
                case 401:
                    return 'Unauthorized';
                case 403:
                    return 'You do not have permission';
                case 404:
                    return 'Not Found';
                case 422:
                    return 'Validation Error';
                case 429:
                    return 'Too Many Requests';
                case 500:
                    return 'Server Error';
                default:
                    return 'Error';
            }
        }

        return 'Error';
    }

    function showErrors(
        error: unknown,
        message: string,
    ): Record<string, string | string[]> {
        if (error instanceof Error) {
            console.error(message, error.message, error);
        } else {
            console.error(message, error);
        }
        if (show) {
            show({
                props: {
                    title: getTitle(error),
                    body: message,
                    variant: 'danger',
                },
            });
        }

        if (error instanceof LaravelError) {
            validationErrors.value = error.errors;
        }

        return error instanceof LaravelError ? error.errors : {};
    }

    return { showErrors, validationErrors };
}
