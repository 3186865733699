import useWarehouseTransferService from '@/api/WarehouseTransfersService';
import { defineStore } from 'pinia';

import { useMenuStore } from '@/stores/menuStore';
import { useOrganisationStore } from '@/stores/organisationStore';

const warehouseTransferService = useWarehouseTransferService();

export const useWarehouseTransferStore = defineStore('warehouseTransfer', {
    state: () => ({
        draftCount: 0,
        problemCount: 0,
    }),
    getters: {},
    actions: {
        async fetchCount() {
            const organisationStore = useOrganisationStore();
            try {
                const ret = await warehouseTransferService.getCounts(
                    organisationStore.current_organisation,
                );
                this.draftCount = ret.draftCount;
                this.problemCount = ret.problemCount;
            } catch (e) {
                this.draftCount = 0;
                this.problemCount = 0;
            }

            const menuStore = useMenuStore();
            if (this.draftCount === 0) {
                menuStore.removeBadge('Upcoming Transfers');
            } else {
                menuStore.addBadge(
                    'Upcoming Transfers',
                    this.draftCount,
                    'warning',
                );
            }

            if (this.problemCount === 0) {
                menuStore.removeBadge('Problem Transfers');
            } else {
                menuStore.addBadge(
                    'Problem Transfers',
                    this.problemCount,
                    'danger',
                );
            }
        },
    },
});
