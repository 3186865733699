import { defineStore } from 'pinia';
import type Dispute from '@/api/model/Dispute';

import useDisputesService from '@/api/DisputesService';
import { useMenuStore } from '@/stores/menuStore';
import { useOrganisationStore } from '@/stores/organisationStore';

const disputesService = useDisputesService();

export const useDisputeStore = defineStore('dispute', {
    state: () => ({
        disputes: [] as Dispute[],
        loading: false,
        openCount: 0,
    }),
    getters: {},
    actions: {
        async fetchCount() {
            const organisationStore = useOrganisationStore();
            try {
                this.openCount = await disputesService.getOpenCount(
                    organisationStore.current_organisation,
                );
            } catch (error) {
                this.openCount = 0;
            }
            const menuStore = useMenuStore();
            if (this.openCount === 0) {
                menuStore.removeBadge('Disputes');
                return;
            } else {
                menuStore.addBadge('Disputes', this.openCount, 'danger');
            }
        },
    },
});
