import type { Order, OrderJson } from '@/api/model/Order';
import type { PurchaseOrderJson } from '@/api/model/PurchaseOrder';
import type PurchaseOrder from '@/api/model/PurchaseOrder';
import type { IReportSource } from '@/api/model/ReportSource';
import ReportSource from '@/api/model/ReportSource';
import type { ToJSON } from '@/api/model/ToJSON';
import type { UserJson } from '@/api/model/User';
import { User } from '@/api/model/User';
import type { IWarehouse } from '@/api/model/Warehouse';
import Warehouse from '@/api/model/Warehouse';
import type { WarehouseTransferItemJson } from '@/api/model/WarehouseTransferItem';
import { WarehouseTransferItem } from '@/api/model/WarehouseTransferItem';
import type { ICountry } from './Country';
import type { IProduct } from './Product';
import { DateTime } from 'luxon';
import type { IFulfillmentCenter } from '@/api/model/FulfillmentCenter';
import FulfillmentCenter from '@/api/model/FulfillmentCenter';

export interface WarehouseTransferJson {
    id?: number;
    created_at: string;
    updated_at: string;
    mws_id: string;
    asin: string;
    product_id: number;
    product?: IProduct;
    countries: Array<ICountry>;
    items: Array<WarehouseTransferItemJson>;
    fulfillment_center: IFulfillmentCenter | null;
    report_source_id: number;
    shipment_id: string | null;
    name: string;
    from: string;

    fulfillment_center_id: number | null;
    status: WarehouseTransferStatus;
    courier: string | null;
    tracking_number: string | null;
    shipping_cost: number | null;
    shipping_cost_currency: string | null;
    shipping_date: string | null;
    shipping_days: number | null;
    outgoing_warehouse_id: number | null;
    outgoing_order_id: number | null;
    outgoing_order_number: string | null;
    destination_warehouse_id: number | null;
    destination_warehouse: IWarehouse | null;
    outgoingOrder: OrderJson | null;
    outgoing_warehouse: IWarehouse | null;
    purchaseOrders: PurchaseOrderJson[] | null;
    reportSource: IReportSource;
    received_date: string | null;
    destination_url: string | null;
    auto_import: boolean;

    ticket_number: string | null;
    ticket_status: 'open' | 'closed' | null;
    ticket_raised_at: string | null;
    ticket_raised_by: UserJson | null;
    resolved_discrepancy: boolean;
    discrepancy_note: string | null;
    resolved_discrepancy_at: string | null;
    resolved_by: UserJson | null;
    closed_at: string | null;
}

export type WarehouseTransferStatus =
    | 'DRAFT'
    | 'WORKING'
    | 'SHIPPED'
    | 'RECEIVING'
    | 'CANCELLED'
    | 'DELETED'
    | 'CLOSED'
    | 'ERROR'
    | 'IN_TRANSIT'
    | 'DELIVERED'
    | 'CHECKED_IN';

export default class WarehouseTransfer
    implements ToJSON<WarehouseTransferJson>
{
    id: number = 0;
    created_at: DateTime = DateTime.now();
    updated_at: DateTime = DateTime.now();
    report_source_id = 0;
    shipment_id: string | null = null;
    name = '';
    from = '';
    fulfillment_center_id = 0;
    status: WarehouseTransferStatus = 'DRAFT';
    items: Array<WarehouseTransferItem> = [];
    fulfillment_center: FulfillmentCenter | null = null;
    courier: string | null = null;
    tracking_number: string | null = null;
    shipping_cost: number | null = null;
    shipping_cost_currency: string | null = 'USD';
    shipping_date: DateTime = DateTime.now();
    shipping_days: number = 5;
    outgoing_warehouse_id: number | null = null;
    outgoing_order_id: number | null = null;
    outgoing_order_number: string | null = null;
    destination_warehouse_id: number | null = null;
    destinationWarehouse: Warehouse | null = null;
    outgoingOrder: Order | null = null;
    outgoingWarehouse: Warehouse | null = null;
    purchaseOrders: PurchaseOrder[] | null = null;
    reportSource: ReportSource = new ReportSource();
    received_date: DateTime | null = null;
    destination_url: string | null = null;
    auto_import = false;
    ticket_number: string | null = null;
    ticket_status: 'open' | 'closed' | null = null;
    ticket_raised_at: DateTime | null = null;
    ticket_raised_by: User | null = null;
    resolved_discrepancy: boolean = false;
    discrepancy_note: string | null = null;
    resolved_discrepancy_at: DateTime | null = null;
    resolved_by: User | null = null;
    closed_at: DateTime | null = null;

    static fromJSON(json: WarehouseTransferJson): WarehouseTransfer {
        //console.debug("WarehouseTransfer.fromJSON()", json);
        const transfer = Object.create(WarehouseTransfer.prototype);
        return Object.assign(transfer, json, {
            created_at: DateTime.fromISO(json.created_at),
            updated_at: DateTime.fromISO(json.updated_at),
            shipping_date: json.shipping_date
                ? DateTime.fromISO(json.shipping_date)
                : null,
            received_date: json.received_date
                ? DateTime.fromISO(json.received_date)
                : null,
            items: json.items?.map((item) =>
                WarehouseTransferItem.fromJSON(item),
            ),
            fulfillment_center: json.fulfillment_center
                ? FulfillmentCenter.fromJSON(json.fulfillment_center)
                : null,
            outgoingWarehouse: json.outgoing_warehouse
                ? Warehouse.fromJSON(json.outgoing_warehouse)
                : null,
            destinationWarehouse: json.destination_warehouse
                ? Warehouse.fromJSON(json.destination_warehouse)
                : null,
            shipping_days: json.shipping_days ?? 5,

            ticket_raised_at: json.ticket_raised_at
                ? DateTime.fromISO(json.ticket_raised_at)
                : null,
            ticket_raised_by: json.ticket_raised_by
                ? User.fromJSON(json.ticket_raised_by)
                : null,
            resolved_discrepancy_at: json.resolved_discrepancy_at
                ? DateTime.fromISO(json.resolved_discrepancy_at)
                : null,
            resolved_by: json.resolved_by
                ? User.fromJSON(json.resolved_by)
                : null,
            closed_at: json.closed_at ? DateTime.fromISO(json.closed_at) : null,
        });
    }

    totalReceived(): number {
        let total = 0;
        for (const item of this.items) {
            total += item.quantity_received;
        }
        return total;
    }

    totalShipped(): number {
        let total = 0;
        for (const item of this.items) {
            total += item.quantity_expected;
        }
        return total;
    }

    expectedDeliveryDate(): DateTime {
        return this.shipping_date.plus({ days: this.shipping_days });
    }

    toJSON(): WarehouseTransferJson {
        //console.debug("Warehouse.toJSON()", this);
        return Object.assign({} as WarehouseTransferJson, this, {
            created_at: undefined,
            updated_at: undefined,
            shipping_date: this.shipping_date?.toISO(),
            items: this.items.map((item) => item.toJSON()),
            reportSource: undefined,
            outgoingWarehouse: undefined,
            destinationWarehouse: undefined,
            outgoingOrder: undefined,
            fulfillment_center: undefined,
        });
    }
}
